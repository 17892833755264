jQuery(document).ready(function ($) {

		// ANCHOR SCROLLS
        function scrollToAnchor(hash) {
            jQuery('html,body').animate({scrollTop: jQuery(hash).offset().top - 80},'slow');
        }

        jQuery('.sub_menu_wrap ul li a').click(function(e) {
            e.preventDefault();
            jQuery('.sub_menu_wrap ul li a').removeClass('active');
            jQuery(this).addClass('active');
            var hash = jQuery(this).attr("href");
            scrollToAnchor(hash);
        });


        jQuery(window).scroll(function() {

            var linksTop = jQuery('#text-image-outer-wrap').offset().top - 20;
            var linksBottom = jQuery('#text-image-outer-wrap').offset().top + jQuery('#text-image-outer-wrap').outerHeight(true);

            if (jQuery(this).scrollTop() > linksTop && jQuery(this).scrollTop() < linksBottom) {
                    jQuery('.sub_menu_wrap').addClass('visible');
            } else {
                jQuery('.sub_menu_wrap').removeClass('visible');
            }
        
        });

        var wrapper = $(".sub_menu_wrap"),
        links = wrapper.find("a"),
        scrollItems = links.map(function() {
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });
         // Bind to scroll
         $( window ).scroll(function() {
            // Get container scroll position
            var fromTop;
            if(jQuery(window).width > 991) {
                fromTop = $(this).scrollTop() + 400;
            } else {
                fromTop = $(this).scrollTop() + 100;
            }

            // Get id of current scroll item
            var cur = scrollItems.map(function() {
                if ($(this).offset().top < fromTop) {
                    return this;
                }
            });
            // Get the id of the current element
            cur = cur[cur.length-1];
            var id = cur && cur.length ? cur[0].id : "";
            // Set/remove active class
            links.removeClass("active");
            // .end().filter("[href='#"+id+"']").addClass("active");
            wrapper.find('[href="#'+ id + '"]').addClass('active');
        });
});